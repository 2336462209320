.Welcome {
   width: 99vw;
   height: 100vh;
   background-image: url('../../../images/Charminar.jpg');
   background-size: cover;
   background-position: center center; /* Set background position to the left and center vertically */
   background-attachment: fixed; /* Keep the background image fixed */
   position: relative; /* Make sure the container is positioned */
   overflow: hidden; /* Hide overflow if the text goes beyond the container */
}

.TextContainer {
   
  user-select: none;
   position: absolute;
   bottom: 20%; /* Adjust as needed */
   left: 50%;
   transform: translateX(-50%);
   font-size: 1.5vw;
   color: beige;
   text-transform: uppercase;
   text-align: center;
   font-weight: bolder;
}
.Hyderabad{
   color: white;
   font-size: 7vw;
}

@media (max-width: 1028px) {
   .TextContainer {
      font-size: 3.5vw;
   }
   .Hyderabad{
      color: white;
      font-size: 12vw;
   }
   
}
