.Timings_table {


   border: 1px solid rgb(224, 224, 224);
   border-radius: 20px;
   padding: 25px;
   background-color: #F9F9FE;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   margin-top: 20px;
}

.Timings_table_title {

   color: rgba(0, 0, 0, 0.788);
   font-size: x-large;
   font-weight: 600;
}

.table-container {
   width: 100%;
   overflow-x: auto;
   border-radius: 10px;
   background-color: transparent;
}

.responsive-table {
   width: 100%;
   margin-top: 30px;
}





.even-row {
   display: flex;
   flex-direction: row;
   padding: 25px;
   border-radius: 70px;
   align-items: center;
   justify-content: space-between;
   background-color: #efeffd;
}

.odd-row {
   display: flex;
   flex-direction: row;
   padding: 30px;
   align-items: center;
   justify-content: space-between;
   gap: 20px;
   background-color: transparent;
}

@media (max-width: 600px) {
   .Place, .Time {
      font-size: small;
      width: 30vw;
      overflow: hidden;
      white-space: nowrap; /* Prevent text from breaking onto the next line */
      text-overflow: ellipsis;
      display: inline-block; /* Ensure the elements are inline-blocks */
      text-align: left;
    }
    
    
   .responsive-table {
      width: 100%;
      display: block;
      margin-top: 5px;
   }

   .table-cell {
      display: block;
      text-align: left;
      border: none;
   }

   .time,
   .text {
      padding: 5px;
   }

   .odd-row,
   .even-row {
      background-color: transparent;
      border-radius: 0;
      padding: 20px;
   }
}