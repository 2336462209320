.Blog_detail {
   background-color: #F5F5FE;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   padding: 30px;
}

.Blog_detail_image {
   background-color: rgb(0, 0, 0);
   width: 80vw;
   height: 40vw;
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   border-radius: 20px;
}
.BLog_detail_date{
   display: flex;
   
   width: 80vw;
   flex-direction: row;
   align-items: center;
   gap: 3px;
   text-transform: capitalize;
   text-align: start;
   font-weight: bolder;
   font-size:1rem;
   color: #363AED;
   line-height: 1.25;
}

.Blog_detail_title {
   width: 80vw;

   text-transform: capitalize;
   text-align: start;
   font-weight: bolder;
   margin-top: 0.5rem;
   margin-bottom: 1.5rem;
   font-size: 1.7rem;
   color: rgb(36 55 87/1);
   line-height: 1.25;
}

.Blog_blocks{
   display: flex;
   flex-direction: column;
}
.Blog_blocks_tiles{
   display: flex;
   flex-direction: row;
   gap: 20px;
}
.Blog_tiles{
   margin-top: 50px;
   border-radius: 20px;
   padding: 10px;
   width: 25vw;
   background-color: white;
   height: fit-content;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.Blog_tiles_title{
   text-transform: uppercase;
   font-weight: 800;
   margin: 20px;
   color: rgba(0, 0, 0, 0.721);


}

@media (max-width: 1028px) {
   .Blog_blocks_tiles{
      display: flex;
      flex-direction: column;
      gap: 20px;
   }
   
.Blog_tiles{
   width: 80vw;
}

}