.Blog_detail_block_image {

   background-color: rgb(0, 0, 0);
   width: 60vw;
   height: 30vw;
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   border-radius: 20px;
}

.Blog_detail_block_desc {
   font-size: 0.9rem;
   line-height: 22px;
   text-align: start;
   padding-top: 0px;
   padding-bottom: 30px;
   padding-left: 30px;
   padding-right: 30px;
   font-weight: 100;
   color: rgba(0, 0, 0, 0.51);
}

.Blog_detail_block {
   margin-top: 50px;
   display: flex;
   flex-direction: column;
   align-items: start;
   justify-content: space-evenly;

   gap: 20px;
   width: 60.1vw;
   background-color: white;
   padding: 5px;
   border-radius: 20px;
}

.Blog_detail_block_title {
   text-align: start;
   color: #243757;
   font-size: 1.25rem;
   padding-left: 30px;
}


@media (max-width: 1028px) {

   .Blog_detail_block_title {
      padding-top: 0px;
      padding-left: 10px;
      padding-right: 10px;
   }

   .Blog_detail_block_desc {
      padding-top: 0px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
   }

   .Blog_detail_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80.1vw;
      margin-top: 10px;
   }

   .Blog_detail_block_image {

      width: 80vw;
      height: 40vw;
   }
}