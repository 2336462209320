.Package_card {
   background-color: white;
  width: 350px;
  height: 500px;
  padding: 6px;
  margin: 15px;
  border-radius: 19px;
  transition: box-shadow 0.3s ease-in-out;
}


.Package_feature_row{
  width: 70px;
  height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Price_couple{
  display: flex;
  flex-direction: row;

}
.Price_readmore{
  width: 310px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}
.link {
  text-decoration: none;
  color: #363AED;
}

.link:hover {
  color: #ffffff;
}
.Readmore{
  border: 1px solid #363AED;
  padding: 15px;
  border-radius: 30px;
  color: #363AED;
  font-size: 14px;
  font-weight: 500;
}

.Readmore:hover{
  background-color: #363AED;
  color: white;
  cursor: pointer;
}
 .Package_features_row{
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
 }
 .Package_card_details{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 200px;
 }
.Package_card:hover {
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
 }
 .Package_img {
  width: 350px; /* Set the fixed width for each image */
  height: auto;
  object-fit: cover;
  border-radius: 19px;
}
/* .Package_image_container{
  width: 320px;
} */

 .Package_card h2 {
   font-size: 1.5rem;
   margin: 0;
 }
 
 .Package_card p {
   font-size: 1rem;
   color: #666;
 }
 .Per_couple{
  font-size: 14px;


 }

 .Package_price{
  color: #363AED;

 }
 .Package_organizer{
  font-weight: 100;
  color: #727272;
  font-size: small;
 }
 .Package_title{
  font-size: large;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #4F4F4F;
 }
.slider-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  max-width: 600px; /* Max width for larger screens */
  margin: 0 auto;
  overflow: hidden;
}

.slider {
  height: 250px;
  display: flex;
  transition: transform 0.3s ease-in-out;
}


.slider-arrow {
  color: #363AED;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 5px;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.slider-arrow:hover {
  
  background-color: #363AED;
  color: white;
}

/* Additional styling for the active and inactive arrow states, if desired */


 @media (max-width: 428px) {
  .Package_card {
    background-color: white;
   width: 90vw;
   height: 500px;
   padding: 6px;
   margin: 15px;
   border-radius: 19px;
   transition: box-shadow 0.3s ease-in-out;
 }
 .Package_features_row{
  color: #4F4F4F;
  width: 80vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
 }

.Price_readmore{
width: 80vw;
}

.Package_feature_row{
  width: 60px;
}
.Package_img {
  width: 90vw; /* Set the fixed width for each image */
  height: auto;
  object-fit: cover;
  border-radius: 19px;
}

}


@media (max-width: 768px) {
  .slider-arrow {
    font-size: 16px; /* Adjust the font size if needed */
    padding: 3px; 
  }
}