.Blogs {
  background-color: #f5f7fec7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 70px;
}

.Blog_title_date{
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
  overflow: hidden;
}
/* Update your Blogs.css file */
.Blog_title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  border: 0 solid #e5e7eb;
  color: rgb(255, 255, 255);
  user-select: none;
  overflow-wrap: break-word !important; /* Add this property for wrapping overflowed text */
  word-wrap: break-word !important; /* Fallback for older browsers */
  
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Blog_date{

  font-size: small;
  font-weight: 200;
  color: #e5e7ebbf;
}

.Sized_box_width400{
  display: flex;

  width: 250px;
  height: 20px;
}
.Blog {
  text-decoration: none;
  cursor: pointer;
  /* background-position: -200px; */
  background-size: cover;
  background-position: center center; 
  
  background-repeat: no-repeat;
  display: flex;
  
  border-radius: 20px;
  position: relative; 
  padding: 20px;
  width: 270px;
  height: 500px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  user-select: none;
  transition: box-shadow 0.3s ease; 
}

.Blog::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  border-radius: 30px;
  background: linear-gradient(to top, #3639ed48, transparent); /* Green gradient */
  transition: height 0.3s ease; /* Transition for smooth effect */
}

.Blog:hover::before {
  border-radius: 30px;
  height: 100%; /* Increase the height on hover */
}

.Blogs_title {
  font-size: 35px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.788);
  text-transform: capitalize;
  user-select: none;
}

.scroll-container {
  display: flex;
  overflow-x: auto; /* Use auto or scroll based on your design preference */
  white-space: nowrap;
  width: 100%; /* Make sure the scroll container takes full width */
}

.scroll-container::-webkit-scrollbar {
  display: none;
}



.Sized_box_height100 {
  height: 50px;
}
