.Contact_us_page{

   background-color: #F5F5FE;
   width: 100vw;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.Contact_us_row{
   display: flex;
   flex-direction: row;
   gap: 5vw;

}
.Contact_us_container{
   width: 20vw;
   background-color: white;
   padding: 40px;
   height: 70vh;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.Phone_no_{
   font-size: 14px;

}
.Phone_no_block_{
   margin-top: 30px;
   width: 90%;
   padding: 12px;
   border-radius: 40px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   border: 0.5px solid rgb(218, 218, 218);
}
.Callback_container{
   background-color: white;
   padding: 20px;
   border-radius: 20px;

}
.Contact_us_title_ ,.Callback_title{
   
   color: rgba(0, 0, 0, 0.788);
   font-size: x-large;
   font-weight: 600;
   text-transform: capitalize;
}
.Callback_container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 30vw;
}
.Callback_title{
   margin-bottom: 30px;
}
.Phone_field_callback_,
.Email_field_callback_,
.Name_field_callback_{
   margin-top: 30px;
   padding-top: 0.5rem;
   height: 35px;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: #f9f9fe;
    border-width: 0.2px;
    border-radius: 9999px;
    width: 80%;
}

@media (max-width: 1028px) {
   .Contact_us_row{
      display: flex;
      flex-direction: column;
      gap: 2vw;
   
   }
   
   .Phone_no_block_{
      padding: 15px;
   }
.Contact_us_container{
   width: 60vw;
   height: 60vh;
}
.Callback_container{
   width: 70vw;
}
.Phone_no_{
   font-size: 14px;
}
.Sized_box_height100{
   height: 100px;
}
.Contact_us_page{
height: auto;
}
}