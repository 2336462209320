.Packages {
   background-color: #F5F5FE;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   padding-top: 70px;
}

.Packages_frame {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   padding: 20px;
}

.Row_spacebetween {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 70vw;
}
 .sized_box_100 {
   width: 120px;
   height: 50px;
}
@media (max-width: 768px) {
   .Row_spacebetween {
      width: 90vw;
   }
}

.View_all {
   text-decoration: none;
   outline: none;
   width: 80px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-evenly;
   border: 1px solid #363AED;
   padding: 15px;
   border-radius: 25px;
   color: #363AED;
   font-weight: 500;
   font-size: 14px;
   gap: 6px;
}

.divider {
   width: 0.3px;
   height:45px;
   background-color: #cccccc67;
 }
 .Horizontal_divider{
   height: 0.3px;
   width:300px;
   background-color: #cccccc7a;
   margin: 2px;

 }


 
@media (max-width: 768px) {
   
 .Horizontal_divider{

   width:80vw;

 }
}
@media (max-width: 768px) {

   /* Adjust styles for smaller screens */
   .View_all {
      width: auto;
      /* Let the width adjust based on content */
      padding: 10px 15px;
      /* Adjust padding for smaller screens */
      font-size: 12px;
      /* Adjust font size for smaller screens */
   }
}

.sized_box_100 {
   width: 60px;
   height: 50px;
}

.View_all:hover {
   text-decoration: none;
   cursor: pointer;
   color: white;
   background-color: #363AED;
}

.Our_packages {
   text-transform: capitalize;
   font-size: 35px;
   font-weight: 600;
   color: rgba(0, 0, 0, 0.788);
}

.Packages_desc {
   width: 40vw;
   margin: 20px;
   font-weight: 200;
   color: rgba(0, 0, 0, 0.658);
   font-size: 14px;
   line-height: 22px;
}

@media (max-width: 768px) {

   /* Adjust styles for smaller screens */
   .Packages_desc {
      width: 90vw;
      /* Reduce the width for smaller screens */
      margin: 10px;
      /* Adjust margin for smaller screens */
   }
}


.carousel-card .carousel img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.carousel-card .card-content {
   padding: 15px;
}

.carousel-card h2 {
   font-size: 18px;
   margin: 0;
}

.carousel-card p {
   font-size: 14px;
   color: #777;
}