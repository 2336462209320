.Package_detail_contact_us{
   background-color: white;
   margin-left: 20px;
   padding: 20px;
   width: 22vw;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   align-items: start;
}
.Price_title{
   margin: 10px;
}
.Price_{
   margin: 6px;
}
.Contact_us_callback{
   margin: 8px;
}
.Price_{
   font-size: 1.5rem;
   line-height: 2rem;
   font-weight: 600;
   color: #243757;
}
.Contact_us_callback{

   display: flex;
   flex-direction: row;
   gap: 10px;
   font-weight:100;
   font-size: 14px;
   color: #7a7a7a;
   

}
.Contact_us_title{
   cursor: pointer;
}

.selected{
   color: #3653EF;
}

@media (max-width: 1028px) {
   .Row{
      flex-direction: column;
   }
   
.Package_detail_contact_us{
   width: 70vw;
   margin: 0px;
   margin-top: 20px;
}
}



@media (max-width: 728px ) {
   
.Package_detail_contact_us{
   width: 80vw;
}
}