
 .phone-icon {
   background-color:#ebebff;
   padding: 10px;
   border-radius: 30px;
   /* color: green; */
   font-size: 17px;
 }
 .Contact_us{
   width: 100%;
   /* margin-top: 20px; */
 }
 .green{
   
   /* color: green; */
 }
 .red{
   /* color: red; */
 }
 
 .blue{
   color: rgb(47, 0, 255);
 }
 .Phone_no_block{
   margin: 20px;
   background-color: #F9F9FE;
   border: 0.5px solid rgb(218, 218, 218);
   border-radius: 30px;
   padding: 15px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-evenly;
 }
 
 .phone-icon:hover {
   background-color:#dcdcff;
   cursor:pointer;
 }
 .Contact_us_button{
   margin-top: 35px;
   margin-bottom: 20px;
   margin-left: 20px;
   margin-right: 20px;
   padding: 12px;
   background-color: #363AED;
   color: #F9F9FE;
   border-radius: 30px;
 }
 .Contact_us_button:hover{
   background-color: #2528b8;
   cursor: pointer;
 }