
 .Whatsappfab{
     
   position:fixed;
   bottom: 100px;
   right: 37px;
   background-color: rgb(41, 41, 41);
   color: aliceblue;
   width: 20px;
   padding: 15px;
   border-radius: 90px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   gap: 2px;
   font-weight: bold;
 }
 
 @media screen and (max-width: 1200px) {
   .Whatsappfab{
      bottom:  100px;
      right: 38px;
   }
 }