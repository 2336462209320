.Charges_title{
   

   color: rgba(0, 0, 0, 0.788);
   font-size: x-large;
   font-weight: 600;
   text-transform: capitalize;

}
.Charges{
   border: 1px solid rgb(224, 224, 224);
   border-radius: 20px;
   padding: 25px;
   background-color: #F9F9FE;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   margin-top: 20px;
   word-break: break-all;
}

.Charges_text{
   
   color: #243757;
   text-align: start;
   margin-top: 5px;
   line-height: 25px;
}
.Charges_list{
   
   color: #243757;
   text-align: start;
   margin-top: 5px;
   line-height: 25px;
}