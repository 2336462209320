.Feedbacks {
   
   background-color: #ffffff;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   padding-top: 70px;
 }
 
 .Feedbacks_title {
   font-size: 35px;
   font-weight: 600;
   color: rgba(0, 0, 0, 0.788);
   text-transform: capitalize;
 }
 
 .Feedback_home { 
   background-color: aliceblue;
   padding: 30px;
   width: 20%;
   min-height: 200px;
   
   margin: 20px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
   gap: 20px;
   cursor: pointer;
   word-wrap: break-word;
   overflow-wrap: break-word;
   transition: box-shadow 0.3s ease; 
 }
 

 .FeedbackName{
   white-space: normal;
   font-style: italic;
   color: rgb(119, 0, 255);
   font-weight: 700;
}

.Feedback_text{
   white-space: normal;

}

.Feedback_package{
color: rgb(51, 51, 51);
   min-width: 20vw;
   white-space: normal;
   font-size: 13px;
}

 .Feedback_home:hover {
   transform: translateY(-3px);
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
 }
 .scroll-container-feedbacks {
   display: flex;
   overflow-x: auto; /* Use auto or scroll based on your design preference */
   white-space: nowrap;
   width: 100%; /* Make sure the scroll container takes full width */
 }
 
 .scroll-container-feedbacks::-webkit-scrollbar {
   display: none;
 }
 
 @media (max-width: 1028px) {
   .Feedback_home{
      width: 70vw;
   }
   .Feedback_package{
      width: 70vw;
      white-space: normal;
   }
   .Feedback_text{
      width: 70vw;
      white-space: normal;
   
   }
   
.FeedbackName{
   width: 70vw;
   white-space: normal;
}

   
}