.Blog_tile{
   padding: 10px;
   width: 90%;
   margin: 5px;
   border-radius: 20px;
   border: 1px solid rgba(0, 0, 0, 0.087);
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-around;
   gap: 20px;

}
.Blog_tile:hover {
   border-color: transparent; /* Change this to the desired hover border color or style */
 }
.Blog_tile_image{
   width: 100px;
   height: 60px;
   border-radius: 10px;
   background-size: cover;
}
.Blog_tile_details{
   display: flex;
   flex-direction: column;
   align-items: start;
   justify-content: space-evenly;
   text-align: start;
   gap: 10px;
}
.Blog_tile_name{
   font-size: 15px;
   color: black;
}
.Blog_tile_date{
   font-size: 10px;
   color: #3639ed48;
}
.Blog_tile:hover{
   background-color: whitesmoke;
   cursor: pointer;
}



@media (max-width: 1028px) {
   .Blog_tile{
      width: 70vw;
   }
   .Blog_tile_name{
      width: 100%;
      font-size: 12px;
   }
}