.Package_main_details{
   border: 1px solid rgb(224, 224, 224);
   border-radius: 20px;
   padding: 25px;
   background-color: #F9F9FE;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   
}
.Package_organizer_{
   color: #363AED;
   font-weight: 600;
   font-size: 1.25rem;
}
.Package_title_{
   text-align: start;
   text-transform: capitalize;
   font-size: 1.875rem;
   color: #243757;
   font-weight: 600;
}


.Vertical_divider_{
   width: 100%;
   height: 0.5px;
   background-color: #e0e0e0;
   margin-top: 5px;
   margin-bottom: 5px;

}


.Package_feature_row_{
   display: flex;
   flex-direction: row;
   gap: 5px;
   align-items: center;
   justify-content: start;
   padding: 5px;
   margin: 2px;
   color: #243757;

}

.Package_features_row_{
   display: flex;
   flex-direction: row;
   gap: 30px;

}
@media (max-width: 1028px) {
   .Package_main_details{
      padding: 25px;
   }
   .Package_features_row_{
      display: flex;
      flex-direction: column;
      gap: 5px;
   
   }
}
