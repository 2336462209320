/* FloatingMenu.css */

.floating-menu {
   border-radius: 30px;
   background-color: rgb(251, 253, 255);
   padding: 5px;
   position: fixed;
   bottom: 20px;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
 }
 
 .menu-item {
   width: 25px;
   padding: 10px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 5px;
   transition: background-color 0.3s;
 }
 
 .menu-item:hover {
   background-color: #3639ed28;
   cursor: pointer;

 }
 
 .icon {
   color: rgb(0, 0, 0);
   font-size: 17px;
 }
 .Tooltip ,.example-container{
  
  border-radius: 7px;
  color: #fff;
  background-color:#363AED;
  font-size: small;
  padding: 8px;
 }
 .Tooltip_arrow ,.example-container{
  
  background-color: #363AED;
 }