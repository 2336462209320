.Row{
   display: flex;
   flex-direction: row;
   margin-top: 30px;
   margin-bottom: 20px;
}

.Package_detail_container{
   width: 50vw;
   background-color: #FFFFFF;
   padding: 30px;
   border-radius: 20px;
}

.Column_{
   display: flex;
   flex-direction: column;
   gap: 20px;
}


@media (max-width: 1028px) {
   .Row{
      flex-direction: column;
   }
   
.Package_detail_container{
   width: 70vw;
   padding: 13px;
}
}



@media (max-width: 728px ) {
   
.Package_detail_container{
   width: 80vw;
}
}