
.Callback_block{
   width: 100%;
   height: 70%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
}
.Phone_field_callback,
.Email_field_callback,
.Name_field_callback{
   margin-top: 10px;
   padding-top: 0.5rem;
   height: 35px;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: #f9f9fe;
    border-width: 0.2px;
    border-radius: 9999px;
    width: 80%;
}
.Callback_button{
   width: 80%;
   margin-top: 35px;
   margin-bottom: 20px;
   margin-left: 20px;
   margin-right: 20px;
   padding: 12px;
   background-color: #363AED;
   color: #F9F9FE;
   border-radius: 30px;
 }
 .Callback_button:hover{
   background-color: #2528b8;
   cursor: pointer;
 }