.Amenities{
   
   
   border: 1px solid rgb(224, 224, 224);
   border-radius: 20px;
   padding: 25px;
   background-color: #F9F9FE;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   margin-top: 20px;
}
.Amenities_title{
   
   color: rgba(0, 0, 0, 0.788);
   font-size: x-large;
   font-weight: 600;
}

.amenities-grid {
   margin-top: 20px;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
   gap: 20px;
 }
 
 .amenity-item {
   display: flex;
   align-items: center;
 }
 
 .amenity-icon {
   margin-right: 10px;
 }
 
 .amenity-text {
   font-size: 16px;
 }
 .Amenities_{
   width: 100%;
   display: flex;
   flex-direction: row;
   gap: 5vw;
 }

 
@media (max-width: 1028px) {
   .Amenities_{
      flex-direction: column;
      gap: 0px;
    }
}