
.Left_square{
   display: flex;
   flex-direction: row;
}
.Right_square{
   display: flex;
   flex-direction: row;

}
.Grid{
   display: flex;
   flex-direction: row;
   align-items: center;
}
.Package_image{
   width: 16vw;
   height: 16vw;
   margin: 10px;
   object-fit:cover;
   border-radius: 20px;
}
.Package_center_image{
   width: 30vw;
   margin: 10px;
   border-radius: 20px;
}




@media (max-width: 1028px) {

.Package_detail {
}
.Grid{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.Package_image{
   width: 26vw;
   height: 26vw;
   border-radius: 20px;
}
.Package_center_image{
   width: 50vw;
   border-radius: 20px;
   
}

}


@media (max-width: 728px ) {

   .Package_detail {
   }
   .Grid{
      display: flex;
      flex-direction: column;
      align-items: center;
   }
   
   .Package_image{
      width: 36vw;
      height: 36vw;
      border-radius: 14px;
   }
   .Package_center_image{
      width: 70vw;
      border-radius: 20px;

   }
   
   }