.Places{
   
   
   
   border: 1px solid rgb(224, 224, 224);
   border-radius: 20px;
   padding: 25px;
   background-color: #F9F9FE;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   margin-top: 20px;
}

.Places_title{
   
   color: rgba(0, 0, 0, 0.788);
   font-size: x-large;
   font-weight: 600;
text-transform: capitalize;
}

.Places_grid{
   width: 100%;
   display: flex;
   flex-direction: row;
   gap: 5vw;
 }

 .Places_column{
   margin-top: 20px;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
   gap: 20px;

 }
 .Place_block{
   gap: 10px;
   display: flex;
   align-items: center;
 }
 .Place_icon{
   background-color: #ECECFD;
   padding: 2px;
   border-radius: 50px;
   color: #7577F2;
 }
 .Place_text{
   font-size: small;
   text-align: start;
 }

 @media (max-width: 1028px) {
   .Places_grid{
      flex-direction: column;
      gap: 0px;
    }
}