.Select_cabs{
   
   border: 1px solid rgb(224, 224, 224);
   border-radius: 20px;
   padding: 25px;
   background-color: #F9F9FE;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   margin-top: 20px;

}
.Select_cabs_title{
   

   color: rgba(0, 0, 0, 0.788);
   font-size: x-large;
   font-weight: 600;
   text-transform: capitalize;
}
.Select_cabs_grid{
   width: 100%;
}
.card-grid {
   width: 95%;
   gap: 30px;
   display: grid;
   grid-template-columns:48% 48%;
   padding: 20px;
 }
 
 .card {
   width: 100%;
   border-radius: 20px;
   border: 0.5px solid rgb(216, 216, 216);

   padding: 5px;
 }
 
 /* .left {
   justify-self: start;
 }
 
 .right {
   justify-self: end;
 } */

 .Cab_image{
   width: 150px;
   height: 90px;
   object-fit: cover;
   border-radius: 20px;
 }

 .Cab_details_row{
   display: flex;
   flex-direction: row;
   gap: 10px;
 }
 .Cab_details_column{
   width: 90%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: start;
   padding: 10px;
 }
 .Cab_title{
   
   text-align: start;
   text-transform: capitalize;
   font-size: 0.9rem;
   color: #243757;
   font-weight: 600;
 }

.Seater{
   
   text-align: start;
   text-transform: capitalize;
   font-size: 0.9rem;
   color: #363AED;
   font-weight: 400;
}

.Cab_price{
   
   text-align: start;
   text-transform: capitalize;
   font-size: 0.9rem;
   color: #000000;
   font-weight: 600;
}

@media (max-width: 1028px) {
   .card-grid {
      width: 95%;
      gap: 10px;
      display: grid;
      grid-template-columns:100%;
      padding: 20px;
    }


.Select_cabs_title{
  padding: 15px;
}
.Select_cabs{
padding: 5px;

}

.Select_cabs_grid{
  width: 90%;
}

.card-grid {
}
}