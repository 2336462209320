.All_packages{
   
   background-color: #F5F5FE;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   padding-top: 70px;
}
.Our_All_packages {
   font-size: 35px;
   font-weight: 600;
   color: rgba(0, 0, 0, 0.788);
   text-transform: capitalize;
}
.All_packages_desc {
   width: 40vw;
   margin: 20px;
   font-weight: 200;
   color: rgba(0, 0, 0, 0.658);
   font-size: 14px;
   line-height: 22px;
}

.All_packages_frame {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   padding: 20px;
}

@media (max-width: 768px) {

   /* Adjust styles for smaller screens */
   .All_packages_desc {
      width: 90vw;
      /* Reduce the width for smaller screens */
      margin: 10px;
      /* Adjust margin for smaller screens */
   }
}
.All_packages_Row_spacebetween{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}