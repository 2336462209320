.Feedback {
   background-color: white;
   margin-left: 20px;
   padding: 20px;
   width: 22vw;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   align-items: start;
}

.Feedback_title {

   color: rgba(0, 0, 0, 0.788);
   font-size: medium;
   font-weight: 600;
   text-transform: capitalize;
   margin: 10px;
}


.Feedback_main {
   background-color: #F9F9FE;
   width: 100%;
   height: 100%;
   border-radius: 20px;
   display: flex;
   flex-direction: column;

}

.Feedback_field,.Feedback_name_field {
   

   margin-top: 10px;
   padding-top: 0.5rem;
   height: 35px;
   padding-bottom: 0.5rem;
   padding-left: 0.75rem;
   padding-right: 0.75rem;
   background-color: #f9f9fe;
   border-width: 0.2px;
   border-radius: 20px;
   width: 80%;
}
.toast-message {
   background: #ffffff !important;
   color: rgb(0, 0, 0) !important;
   font-size: 17px;
   width: 34vw;
   padding: 30px 20px;
}
.Feedback_field.increased-height {
   border-radius: 20px;
   height: 80px;
   padding: 20px;
   resize: vertical;
}

.Feedback_main{
   display: flex;
   flex-direction: column;
   align-items: center;
}


@media (max-width: 1028px) {
   .Feedback {
      width: 70vw;
   }
   
.toast-message {
   width: 90vw;
}
}