
.Package_features_column{

}
.Package_feature_icon_title{
   font-size: 10px;
   color: rgb(54, 54, 54);
}
.Package_features_column{
   width: 50px;
}